/**
 * StreamField
 */

import React from 'react';
import classNames from 'classnames';
import AjaxLoader from 'Components/AjaxLoader';
import * as Components from 'Components/modules';
import s from './StreamField.module.scss';

const StreamField = ({ items, currentPage, }) => {
    const components = items.map(function (item, i) {
        const componentName = item.component;
        const Component = Components[componentName];
        if (!Component) {
            return null;
        }

        const identifier = `streamfield-${i}`;

        // No spacing for fullwidth components
        const getNoSpacing = (checkItem) => {
            const checkCompName = checkItem.component;
            const withoutSpacing = [
                'NewsletterSubscription',
                'ImageDivider',
                'CardPromo',
                'AnchorDestination',
            ];
            if(checkCompName === 'CardArticleFull' && (
                (checkItem.imageAsBackground && checkItem.image) || checkItem.modifier
            )) {
                return true;
            } else if(checkCompName === 'CardFact' && checkItem.wide) {
                return true;
            } else if(withoutSpacing.includes(checkCompName)) {
                return true;
            } else {
                return false;
            }
        };

        const embeddedComponent = ['Embedded'];
        const isEmbedded = embeddedComponent.includes(componentName);

        const hideableComponents = [
            'CardEditorialList',
            'CardNewsList',
            'CardResourceList'
        ];

        // Set spacing depending on component, props or order of components
        const noSpacing = getNoSpacing(item);

        const classes = classNames(
            s['StreamField__Component'],
            [s[`StreamField__Component--${componentName}`]],
            {[s['StreamField__Component--FullWidth']]: item && item.fullWidth },
            {[s['StreamField__Component--NoSpacing']]: noSpacing },
            {[s['StreamField__Component--Embedded']]: isEmbedded },
        );

        if (hideableComponents.includes(componentName)) {
            return (
                <HidableStreamFieldComponent
                    classes={classes}
                    item={item}
                    key={i}
                    identifier={identifier}
                    currentPage={currentPage}
                    component={Component}
                />
            );
        }
        return (
            <div className={classes} key={i}>
                <Component {...item} currentPage={currentPage} identifier={identifier} />
            </div>
        );
    });

    return <div className={s['StreamField']}>{components}</div>;
};

class HidableStreamFieldComponent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            hideLoader: false,
        };
    }

    showComponent = () => {
        this.setState({
            hidden: false
        });
    }

    hideComponent = () => {
        this.setState({
            hidden: true,
            hideLoader: true,
        });
    }

    render() {
        const { item, currentPage, component, identifier, classes } = this.props;
        const { hidden, hideLoader } = this.state;
        const componentName = item.component;
        const Component = component;

        const newClasses = classNames(
            classes,
            {[s['StreamField__Component--Hidden']]: hidden },
            {[s['StreamField__Component--Visible']]: !hidden },
        );

        return (
            <React.Fragment>
                <div className={newClasses}>
                    <Component
                        {...item}
                        identifier={identifier}
                        onShowComponent={this.showComponent}
                        onHideComponent={this.hideComponent}
                        currentPage={currentPage}
                    />
                </div>
                {hidden && !hideLoader && (
                    <div className={s['StreamField__Loader']}>
                        <AjaxLoader />
                    </div>
                )}
            </React.Fragment>
        );
    }
}

StreamField.defaultProps = {
    items: [],
    identifier: '',
};

export default StreamField;
