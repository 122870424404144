import dynamic from 'next/dynamic';

const CardMapItem = dynamic(() => import('./CardMapItem'));
const MapSearch = dynamic(() => import('./MapSearch'));
const Map = dynamic(() => import('./Map'));
const EmbedMessage = dynamic(() => import('./EmbedMessage'));
const Tabs = dynamic(() => import('./Tabs'));
const Title = dynamic(() => import('./Title'));
const TagList = dynamic(() => import('./TagList'));
const SubMenu = dynamic(() => import('./SubMenu'));
const BackgroundImage = dynamic(() => import('./BackgroundImage'));
const AnchorDestination = dynamic(() => import('./AnchorDestination'));
const CardLogoList = dynamic(() => import('./CardLogoList'));
const CardLogo = dynamic(() => import('./CardLogo'));
const ImageCarouselItem = dynamic(() => import('./ImageCarouselItem'));
const ImageCarousel = dynamic(() => import('./ImageCarousel'));
const SearchPagination = dynamic(() => import('./SearchPagination'));
const CardResource = dynamic(() => import('./CardResource'));
const CardResourceList = dynamic(() => import('./CardResourceList'));
const SocialMediaLinks = dynamic(() => import('./SocialMediaLinks'));
const SearchHit = dynamic(() => import('./SearchHit'));
const SearchAutocompleteResult = dynamic(() => import('./SearchAutocompleteResult'));
const HeaderSearch = dynamic(() => import('./HeaderSearch'));
const StreamField = dynamic(() => import('./StreamField'));
const ImageDivider = dynamic(() => import('./ImageDivider'));
const HeroSearch = dynamic(() => import('./HeroSearch'));
const Embedded = dynamic(() => import('./Embedded'));
const FaqList = dynamic(() => import('./FaqList'));
const HeaderSubMenuPromotion = dynamic(() => import('./HeaderSubMenuPromotion'));
const FilterSelect = dynamic(() => import('./FilterSelect'));
const CardContactPersonList = dynamic(() => import('./CardContactPersonList'));
const EntryList = dynamic(() => import('./EntryList'));
const TranslationBanner = dynamic(() => import('./TranslationBanner'));
const HeaderSubMenu = dynamic(() => import('./HeaderSubMenu'));
const HeaderMenu = dynamic(() => import('./HeaderMenu'));
const ButtonReadMore = dynamic(() => import('./ButtonReadMore'));
const ArrangementEntry = dynamic(() => import('./ArrangementEntry'));
const ArrangementEntryList = dynamic(() => import('./ArrangementEntryList'));
const ArrangementInformationNonEditorConfigurator = dynamic(() => import('./ArrangementInformationNonEditorConfigurator'));
const ArrangementConfigurators = dynamic(() => import('./ArrangementConfigurators'));
const ArrangementInformationConfigurator = dynamic(() => import('./ArrangementInformationConfigurator'));
const InfoBox = dynamic(() => import('./InfoBox'));
const WufooEmbedForm = dynamic(() => import('./WufooEmbedForm'));
const ArrangementPosterConfigurator = dynamic(() => import('./ArrangementPosterConfigurator'));
const CardArrangementOffice = dynamic(() => import('./CardArrangementOffice'));
const CardAjaxLoader = dynamic(() => import('./CardAjaxLoader'));
const Logo = dynamic(() => import('./Logo'));
const ExpandableContactPerson = dynamic(() => import('./ExpandableContactPerson'));
const HeroArrangement = dynamic(() => import('./HeroArrangement'));
const CardContactPersonDetailed = dynamic(() => import('./CardContactPersonDetailed'));
const CardOffice = dynamic(() => import('./CardOffice'));
const AjaxLoaderBar = dynamic(() => import('./AjaxLoaderBar'));
const AjaxLoader = dynamic(() => import('./AjaxLoader'));
const PeopleSearch = dynamic(() => import('./PeopleSearch'));
const Footer = dynamic(() => import('./Footer'));
const ArrangementSearchFilters = dynamic(() => import('./ArrangementSearchFilters'));
const ArrangementSearch = dynamic(() => import('./ArrangementSearch'));
const NavMain = dynamic(() => import('./NavMain'));
const ArrangementSearchResult = dynamic(() => import('./ArrangementSearchResult'));
const CardArrangement = dynamic(() => import('./CardArrangement'));
const CardDownload = dynamic(() => import('./CardDownload'));
const CardImage = dynamic(() => import('./CardImage'));
const CardImageText = dynamic(() => import('./CardImageText'));
const CardVideo = dynamic(() => import('./CardVideo'));
const CardVideoText = dynamic(() => import('./CardVideoText'));
const CardFact = dynamic(() => import('./CardFact'));
const ExpandableCard = dynamic(() => import('./ExpandableCard'));
const CardContactPerson = dynamic(() => import('./CardContactPerson'));
const Breadcrumbs = dynamic(() => import('./Breadcrumbs'));
const ArrangementFacts = dynamic(() => import('./ArrangementFacts'));
const ResourceList = dynamic(() => import('./ResourceList'));
const HeroMethod = dynamic(() => import('./HeroMethod'));
const CardMethod = dynamic(() => import('./CardMethod'));
const CardMethodList = dynamic(() => import('./CardMethodList'));
const CardNews = dynamic(() => import('./CardNews'));
const CardNewsList = dynamic(() => import('./CardNewsList'));
const CardEditorial = dynamic(() => import('./CardEditorial'));
const CardEditorialList = dynamic(() => import('./CardEditorialList'));
const CardRegion = dynamic(() => import('./CardRegion'));
const HeroArticle = dynamic(() => import('./HeroArticle'));
const CarouselComponent = dynamic(() => import('./CarouselComponent'));
const CarouselQuote = dynamic(() => import('./CarouselQuote'));
const CardQuote = dynamic(() => import('./CardQuote'));
const CardPromo = dynamic(() => import('./CardPromo'));
const CardLinkList = dynamic(() => import('./CardLinkList'));
const CardArticleFull = dynamic(() => import('./CardArticleFull'));
const CardEntry = dynamic(() => import('./CardEntry'));
const Hero = dynamic(() => import('./Hero'));
const Button = dynamic(() => import('./Button'));
const Wysiwyg = dynamic(() => import('./Wysiwyg'));
const ArrangementApplication = dynamic(() => import('./ArrangementApplication'));
const NewsletterSubscription = dynamic(() => import('./NewsletterSubscription'));
const SpotifyEmbedBlock = dynamic(() => import('./SpotifyEmbedBlock'));
const Feedback = dynamic(() => import('./Feedback'));
const CardOfficeLarge = dynamic(() => import('./CardOfficeLarge'));
const ArticleList = dynamic(() => import('./ArticleList'));

export {
    CardMapItem,
    MapSearch,
    Map,
    EmbedMessage,
    Tabs,
    Title,
    TagList,
    SubMenu,
    BackgroundImage,
    AnchorDestination,
    CardLogoList,
    CardLogo,
    ImageCarouselItem,
    ImageCarousel,
    SearchPagination,
    CardResource,
    CardResourceList,
    SocialMediaLinks,
    SearchHit,
    SearchAutocompleteResult,
    HeaderSearch,
    StreamField,
    ImageDivider,
    HeroSearch,
    Embedded,
    FaqList,
    HeaderSubMenuPromotion,
    FilterSelect,
    CardContactPersonList,
    EntryList,
    TranslationBanner,
    HeaderSubMenu,
    HeaderMenu,
    ButtonReadMore,
    ArrangementEntry,
    ArrangementEntryList,
    ArrangementInformationNonEditorConfigurator,
    ArrangementConfigurators,
    ArrangementInformationConfigurator,
    InfoBox,
    WufooEmbedForm,
    ArrangementPosterConfigurator,
    CardArrangementOffice,
    CardAjaxLoader,
    Logo,
    ExpandableContactPerson,
    HeroArrangement,
    CardContactPersonDetailed,
    CardOffice,
    AjaxLoaderBar,
    AjaxLoader,
    PeopleSearch,
    Footer,
    ArrangementSearchFilters,
    ArrangementSearch,
    NavMain,
    ArrangementSearchResult,
    CardArrangement,
    CardDownload,
    CardImage,
    CardImageText,
    CardVideo,
    CardVideoText,
    CardFact,
    ExpandableCard,
    CardContactPerson,
    Breadcrumbs,
    ArrangementFacts,
    ResourceList,
    HeroMethod,
    CardMethod,
    CardMethodList,
    CardNews,
    CardNewsList,
    CardEditorial,
    CardEditorialList,
    CardRegion,
    HeroArticle,
    CarouselComponent,
    CarouselQuote,
    CardQuote,
    CardPromo,
    CardLinkList,
    CardArticleFull,
    CardEntry,
    Hero,
    Button,
    Wysiwyg,
    ArrangementApplication,
    NewsletterSubscription,
    SpotifyEmbedBlock,
    Feedback,
    CardOfficeLarge,
    ArticleList,
};
